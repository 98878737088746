import React from 'react'
import Grid from '../components/Grid'
import LocationButton from '../components/LocationButton'

import ribs from '../images/ribs.jpg'
import yelp from '../images/Yelp_burst_negative_RGB.png'
import Layout from '../layouts/layout'

const IndexPage = () => (
	<Layout>
		<div>
			<img src={ribs} alt="Delicious BBQ Ribs on cutting board" />
			<h1>Locations</h1>
			<Grid>
				<div>
					<h2>Vista - Downtown</h2>
					<LocationButton
						className="frontpage-button pink-button"
						href="/menu-vista-downtown"
					>
						Menu
					</LocationButton>
					<LocationButton
						className="frontpage-button pink-button"
						href="https://orders.cake.net/11085942"
					>
						Order Online
					</LocationButton>
					<LocationButton
						className="frontpage-button gray-button"
						target="_blank"
						href="https://www.google.com/maps/place/When+Pigs+Fly+BBQ/@33.2026543,-117.2440956,17z/data=!3m1!4b1!4m5!3m4!1s0x80dc7698c2ac7419:0xc05733f1507efd70!8m2!3d33.2026498!4d-117.2419069"
					>
						Map/Directions
					</LocationButton>
					<LocationButton
						className="frontpage-button gray-button"
						target="_blank"
						href="https://www.yelp.com/biz/when-pigs-fly-vista"
					>
						<img src={yelp} className="yelp" alt="" />
						Yelp
					</LocationButton>
					<br />
					<p>
						<strong>
							230 Main St
							<br />
							Vista, CA 92084
						</strong>
					</p>
					<p>
						Monday - Thursday 11am-8pm
						<br />
						Friday - Saturday 11am-10pm
						<br />
						Closed Sunday
					</p>
					<p>(760) 295-7993</p>
				</div>
				<div>
					<h2>Vista - Foothill</h2>
					<LocationButton
						className="frontpage-button pink-button"
						href="/menu-vista-foothill"
					>
						Menu
					</LocationButton>
					<LocationButton
						className="frontpage-button pink-button"
						href="https://www.doordash.com/store/when-pigs-fly-bbq-vista-86223/"
					>
						Order Delivery
					</LocationButton>
					<LocationButton
						className="frontpage-button gray-button"
						target="_blank"
						href="https://www.google.com/maps/place/When+Pigs+Fly+BBQ/@33.217094,-117.22392,16z/data=!4m8!1m2!2m1!1sWhen+Pigs+Fly+BBQ!3m4!1s0x0:0xceb074c420b50f1d!8m2!3d33.2176252!4d-117.2254166"
					>
						Map/Directions
					</LocationButton>
					<LocationButton
						className="frontpage-button gray-button"
						target="_blank"
						href="https://www.yelp.com/biz/when-pigs-fly-bbq-vista-3"
					>
						<img src={yelp} className="yelp" alt="" />
						Yelp
					</LocationButton>
					<br />
					<p>
						<strong>
							1211 E. Vista Way
							<br />
							Vista, CA 92084
						</strong>
					</p>
					<p>
						Monday - Thursday 11am-7pm
						<br />
						Friday - Saturday 11am-8pm
						<br />
						Closed Sunday
					</p>
					<p>(760) 945-7828</p>
				</div>
			</Grid>
		</div>
	</Layout>
)

export default IndexPage
